import React, { useState, useEffect } from 'react'
import Layout from 'components/layout'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import GithubButton from 'components/buttons/github'
import * as queryString from 'query-string'
import { fontSizes } from 'utils/typography'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SecondaryParagraph = styled.p`
  margin-top: 0rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: ${ fontSizes.small };
`

const GITHUB_APP_CLIENT_ID = process.env.GATSBY_GITHUB_APP_CLIENT_ID
const API_ROOT = process.env.GATSBY_API_URL

const SecondPage = props => {
  const [userConfigId, setUserConfigId] = useState()
  const [loading, setLoading] = useState(false)

  // since gatsby hydrates HTML location.search is going to be undefined (it was pre-compiled)
  // we are updating it by using effect hook, to cause component re-render
  useEffect(() => {
    const { user_config_id: userConfigIdFromQuery } = queryString.parse(props.location.search)
    setUserConfigId(userConfigIdFromQuery)
    setLoading(true)
  }, [props.location.search])

  const redirectUrl = `${ API_ROOT }/github/oauth-redirect?user_config_id=${ userConfigId }`

  const githubAuthUrl =
    `https://github.com/login/oauth/authorize?client_id=${ GITHUB_APP_CLIENT_ID }` +
    `&state=make-me-a-toast&redirect_uri=${ redirectUrl }`

  return (
    <Layout>
      <Container>
        <h1>Step 2</h1>
        <div
          css={css`
            margin-bottom: 0.4rem;
          `}
        >
          <GithubButton loading={loading} text="Add to GitHub" url={githubAuthUrl} />
        </div>
        <SecondaryParagraph>
          Toast <b>cannot</b> access your code.
        </SecondaryParagraph>
      </Container>
    </Layout>
  )
}

export default SecondPage
